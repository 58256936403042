import React, { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AnimatedRoutes from './router';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './style/style.css'
import store from './store/store'

const App = () => {
  useEffect(() => {
    if (localStorage.getItem("lang") === null || localStorage.getItem("lang") === undefined) {
      localStorage.setItem("lang", "EN");
    }
    if (localStorage.getItem("version") === null || localStorage.getItem("version") === undefined) {
      localStorage.clear()
      localStorage.setItem("version", 28)
    } if (localStorage.getItem("version") !== "28") {
      localStorage.clear()
      localStorage.setItem("version", 28)
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <AnimatedRoutes />

      </Router>
      <ToastContainer />
    </Provider>
  );
};

export default App;
