import React, { useEffect, useState } from 'react';
import { API_URL } from '../../api/api';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Job from '../../components/job';
import he from 'he';
import { motion } from 'framer-motion';
import { mobilVariant } from '../../components/animation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ChevronsDown, ChevronUp } from 'react-feather';
import { animateScroll as scroll } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageByHeaders } from '../../store/slices/pageByHeaderSlice';
import { fetchHeaders } from '../../store/slices/headerSlice';
import { useNavigate } from 'react-router-dom';
import ApplicationPage from '../applicationPage';

const ContentPage = () => {
    const navigate = useNavigate();
    const headerID = localStorage.getItem("headerID");
    const dispatch = useDispatch();
    const data = useSelector((state) => state.pageByHeader.page);
    const headers = useSelector((state) => state.header.menus);
    const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");
    const [load, setLoad] = useState(false);
    const [displayText, setDisplayText] = useState('');
    const [datas, setDatas] = useState(null);
    const [pageText, setPageText] = useState(null);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [showJobs, setShowJobs] = useState(false);
    const [showApplication, setShowApplication] = useState(false);

    const cleanHtml = (html) => {
        if (html) {
            let cleanedHtml = he.decode(html);
            cleanedHtml = cleanedHtml.replace(/<\/?pre[^>]*>/g, '');
            cleanedHtml = cleanedHtml.replace(/<\/?script[^>]*>/g, '');
            return cleanedHtml;
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.setLanguageToTurkish = () => {
            localStorage.setItem("lang", "TR");
            if (window.location.pathname.includes("personal-data-protection")) {
                window.location.href = "/kisisel-verilerin-korunmasi";
            }
        };
    }, []);

    const getData = () => {
        let url = "";
        if (Array.isArray(headers)) {
            headers.forEach(element => {
                if (Array.isArray(element.subHeaders)) {
                    element.subHeaders.forEach(element2 => {
                        if (Number(headerID) === element2.headerID) {
                            url = lang === "TR" ? element2.url : element2.urlEN;
                        }
                    });
                }
            });
        }
        setDatas(data);
        setDisplayText(lang === "TR" ? data.imageText : data.imageTextEN);
        setPageText(lang === "TR" ? data.content : data.contentEN);
        if (url) {
            console.log(url);
            navigate(lang === "TR" ? `/${url}` : `/${url}`);
        }
        if (datas !== undefined && datas !== null) {
            setTimeout(() => {
                setLoad(true);
            }, 500);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchHeaders());
                await dispatch(fetchPageByHeaders());
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        AOS.init({ duration: 0, once: true });
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            getData();
        }
    }, [data, headers]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 700) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash) {
                setTimeout(() => {
                    const targetElement = document.querySelector(hash);
                    if (targetElement) {
                        targetElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    }
                }, 500);
            }
        };

        handleHashChange();

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    useEffect(() => {
        const images = document.querySelectorAll("img");
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("visible");
                    observer.unobserve(entry.target);
                }
            });
        });

        images.forEach(image => {
            observer.observe(image);
        });

        return () => {
            images.forEach(image => {
                observer.unobserve(image);
            });
        };
    }, []);

    useEffect(() => {
        if (load) {
            setTimeout(() => {
                const hash = window.location.hash;
                if (hash) {
                    const targetElement = document.querySelector(hash);
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }, 100);
        }
    }, [load]);

    const scrollToSection = () => {
        scroll.scrollMore(window.innerHeight * 1, {
            duration: 0,
            smooth: "easeInOutQuart",
        });
    };

    useEffect(() => {
        AOS.init({
            duration: 500,
            once: true,
        });
    }, []);

    useEffect(() => {
        if (pageText) {
            AOS.refresh();
        }
    }, [pageText]);


    useEffect(() => {
        const currentPath = window.location.pathname.toLowerCase();

        const cleanPath = currentPath.replace(/^\/|\/$/g, '');
        // console.log("Mevcut Yol:", currentPath);
        if (cleanPath === 'career' || cleanPath === 'kariyer') {
            setShowJobs(true);
            // console.log("Show Jobs: True");
        }
        else if (cleanPath === 'join-mapa' || cleanPath === 'mapaya-katilin') {
            setShowApplication(true);
            // console.log("Show Application: True");
        }
        else {
            setShowJobs(false);
            setShowApplication(false);
            // console.log("Show Jobs & Application: False");
        }
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <div className='contentpage'>
                {data ? (
                    <>
                        <Header />
                        <div className={displayText !== undefined && displayText !== "" && displayText !== null ? displayText.length > 50 ? 'top' : 'top bottom' : null}>
                            <div className={`w-100 ${displayText !== undefined && displayText !== "" && displayText !== null ? displayText.length > 50 ? 'd-flex align-items-center' : '' : null}`}>
                                {data.fileFullPath && <img src={`${API_URL}${data.fileFullPath}`} alt='' />}
                                <div className={displayText !== undefined && displayText !== "" && displayText !== null ? displayText.length > 50 ? 'text' : 'text bottom' : null}>
                                    <div dangerouslySetInnerHTML={{ __html: cleanHtml(displayText) }}></div>
                                </div>
                                <div className='bottombtn shadow' onClick={scrollToSection}>
                                    <ChevronsDown size={35} />
                                </div>
                            </div>
                        </div>
                        <div className='showcase'>
                            <div dangerouslySetInnerHTML={{ __html: cleanHtml(pageText) }}>
                            </div>
                            {showJobs && (
                                <Job />
                            )}

                            {showApplication && (
                                <ApplicationPage />
                            )}



                        </div>
                        <Footer />
                    </>
                ) : (
                    <>
                        <Header />
                        <h2 style={{ marginTop: 300, textAlign: 'center' }}>
                            {lang === "TR" ? "İçerik girilmemiş" : "No Content"}
                        </h2>
                    </>
                )}
                {showScrollToTop && (
                    <div className='scroll-to-top' onClick={scrollToTop}>
                        <ChevronUp size={30} />
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default ContentPage;
